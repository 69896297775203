@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@supports (-webkit-touch-callout: none) {

  /* CSS specific to iOS devices */
  .layout-content {
    padding-top: 5.75rem;
  }

  .template-alert {
    top: 30px;
  }

  .layout-topbar-backdrop {
    top: 30px;
  }
}

:root {
  --primary-text-color: #2167A1;
  --primary-bg-color: #2167A1;
  --secondary-text-color: rgb(110, 107, 123);
  --secondary-bg-color: rgb(110, 107, 123);
  --body-bg-color: #f8f8f8;
  --body-fg-color: rgb(110, 107, 123);
  --topbar-bg-color: #FFFFFF;
  --topbar-fg-color: #030303;
  --sidebar-bg-color: #434B54;
  --sidebar-fg-color: #E5E8EC;
  --color-primary: #2167A1;
  --color-secondary: rgb(110, 107, 123);
  --color-danger: #ea5455;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: "Montserrat";
  color: var(--body-fg-color);
  background: #EFF8F6;
  /* var(--body-bg-color);*/
  /*-webkit-font-smoothing: antialiased;*/
  font-size: 14px;
  overflow-x: hidden;
}

body a {
  text-decoration: none;
  cursor: pointer;
}

.version-info {
  font-size: 8px;
  color: white;
  text-align: center;
}

.version-info > div {
  background: rgb(0,0,0,0.3);
  padding: 5px;
  border-radius: 5px;
  width: 50%;
  margin-left: 25%;
}

.version-info-login {
  font-size: 10px;
  color: gray;
}

/* loaders */

/* default loader */
.app-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0px;
  left: 0px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #000000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* top line loader */
.app-loader-top {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgb(128, 128, 128, 0.1);
  top: 0px;
  left: 0px;
}

.slider {
  width: 100%;
  height: 5px;
  overflow-x: hidden;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: #4EA294;
  width: 150%;
  height: 5px;
}

.subline {
  position: absolute;
  background: #4EA294;
  height: 5px;
}

.inc {
  animation: increase 2s infinite;
}

.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }

  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }

  to {
    left: 110%;
    width: 10%;
  }
}

/* component top loader */
.component-loader-top {
  position: absolute;
  width: 100%;
  z-index: 99999;
  top: 0px;
  left: 0px;
}

.component-loader-top .p-progressbar {
  height: 3px !important;
}

/* end loaders */


a:visited,
a:hover,
a:active {
  color: inherit;
}

*,
:after,
:before {
  box-sizing: border-box;
}

* {
  font-family: "Montserrat";
}

.h-100 {
  height: 100% !important;
}

b {
  font-weight: 600 !important;
}


/* layout general */

.logged-out .layout-topbar-backdrop {
  display: none !important;
}

.logged-out .layout-topbar {
  display: none !important;
}

.logged-out .layout-sidebar {
  display: none !important;
}

.logged-out .layout-content {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}


/* end layout general */


/* h */

h1,
h2,
h3,
h4 {
  font-weight: 500;
  color: #5e5873;
}

h1 {
  font-weight: 600;
  font-size: 24px;
}

h2 {
  font-weight: 500;
  font-size: 20px;
}

h3 {
  font-weight: 500;
  font-size: 17px;
}

h4 {
  font-weight: 500;
  font-size: 14px;
}


/* end h */


/* toast y messages */

.p-toast {
  max-width: 80%;
}

.p-inline-message {
  display: block !important;
}


/* end toast */


/* grid */

.flex-grow-1 {
  flex-grow: 1;
}

.p-grid {
  /*margin: 0px;*/
}

.p-grid-nogutter {
  margin: -0.5rem;
}


/* end grid */


/* colores */

.color-danger {
  color: var(--color-danger) !important;
}


/* end colores */


/* topbar */

.layout-topbar-backdrop {
  position: fixed;
  z-index: 11;
  top: 0px;
  right: 0px;
  /*background-color: #f9f9f9 ;*/
  /*var(--body-bg-color);*/
  width: calc(100% - 260px);
  height: 75px;
}

.layout-topbar {
  -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background: var(--topbar-bg-color);
  color: var(--topbar-fg-color);
  position: initial;
  width: calc(100% - 4rem - 260px);
  z-index: 12;
  right: 0px;
  padding: .8rem 1rem;
  color: var(--body-fg-color);
  width: 100%;
}

.layout-topbar-floating {
  margin: 1.3rem 2rem 0;
  border-radius: .428rem;
}

.vertical-menu.menu-collapsed .layout-topbar-backdrop {
  width: calc(100% - 4.4rem - 74px);

}

.vertical-menu.menu-collapsed .layout-topbar-backdrop {
  padding-left: 80px !important;
  width: 100%;
}

.vertical-menu.menu-collapsed .layout-sidebar {
  width: 80px;

  .p-toggleable-content,
  .pi-chevron-down,
  .pi-chevron-right,
  .p-menuitem-text,
  .version-info {
    display: none;
  }
}

.vertical-menu.menu-collapsed .layout-sidebar:hover {
  width: 260px;

  .ap-toggleable-content,
  .pi-chevron-down,
  .pi-chevron-right,
  .p-menuitem-text,
  .version-info {
    display: unset;
  }

  .p-toggleable-content:not([style*="overflow: hidden;"]) {
    display: unset;
  }
}


.vertical-menu.menu-collapsed .layout-sidebar .titleMenu {
  visibility: hidden;
  height: 20px;
}

.vertical-menu.menu-collapsed .layout-sidebar .logo_sm {
  display: block;
}

.vertical-menu.menu-collapsed .layout-sidebar .logo {
  display: none;
}

.horizontal-menu .layout-topbar {
  width: 100%;
  margin: 0px;
  border-radius: 0px;
  -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.layout-topbar a {
  /* margin-left: 10px;
    margin-right: 10px;*/
}

.layout-topbar a>.topbar-icon .p-badge {
  height: 14px;
  width: 14px;
  min-width: 14px;
  font-size: 10px;
  line-height: 14px;
}

.user-dropdown {
  text-align: right;
  color: #6e6b7b;
}

.user-dropdown img {
  border-radius: 50%;
  margin-left: 5px;
}

/* end topbar */
/* sidebar */

.layout-sidebar {
  width: 260px;
  -webkit-transition: .4s cubic-bezier(.25, .8, .25, 1), background 0s;
  transition: .4s cubic-bezier(.25, .8, .25, 1), background 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  background: #606060;
  /* #fff;*/
  height: 100%;
  z-index: 1031;
  position: fixed;
  display: table-cell;
}


.horizontal-menu .layout-sidebar {
  position: fixed;
  display: block;
  width: calc(100% - 4rem);
  height: auto;
  top: 55px;
  margin: 1.3rem 2rem 0;
  border-radius: .428rem;
  -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.layout-sidebar .logo_sm {
  display: none;
  text-align: center;
  padding: 10px;
  background: white;
}

.layout-sidebar .logo {
  margin: 0px 20px 10px 20px;
  background: white;
}

.layout-sidebar .logo img {
  max-width: 100%;
}

.horizontal-menu .layout-sidebar .logo {
  display: none !important;
}

.layout-sidebar .layout-menu {
  margin-left: 20px;
  margin-right: 0px;
}

.layout-sidebar .layout-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: -20px
}

.layout-sidebar .layout-menu ul li {
  padding: 0;
  margin-left: 10px;
  margin-top: 1px;
}

.horizontal-menu .layout-sidebar ul li {
  padding: 0;
  margin-top: 0px;
  display: inline-block;
}

.layout-sidebar .layout-menu ul li a {
  color: #625f6e;
  margin: 0px;
  padding: 10px 20px 10px 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 13px;
  font-weight: 500;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  border-radius: 10px 0px 0px 10px;
}

.horizontal-menu .layout-menu ul li a {
  padding: 0;
  display: inline-block;
  border-radius: 10px;
  padding: 7px 10px;
  color: #625f6e;
  font-weight: 400;
  font-size: 13px;
}

.layout-sidebar .layout-menu ul li a:hover {
  background-color: #f9f9f9;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.layout-sidebar .layout-menu ul li a.active {
  background-color: #f8f8f8;
  color: var(--primary-text-color);
  font-weight: 500;
}

.horizontal-menu .layout-menu .layout-menugroup-text {
  display: none;
}

.layout-sidebar .layout-menugroup-text {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #CDD2DB;
  letter-spacing: .01rem;
}

.layout-sidebar .layout-menuitem-icon {}

.layout-sidebar .layout-menuitem-text {
  padding-left: 5px;
}

.layout-sidebar-minimized {
  width: 50px;
}

.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
  background-color: transparent !important;
  font-size: 13px !important;
}

.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
  color: white !important;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-icon-wrapper {
  order: 1;
  margin-left: auto;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-icon-wrapper svg {
  margin-right: 0px !important;
  height: 0.9rem;
  width: 0.9rem;
}

/* end sidebar */
/* right sidebar bar */
.layout-sidebar-right {
  padding-top: 30px;
  margin-left: -16px;
}

.layout-sidebar-right a {
  display: block;
  color: #625f6e;
  padding: 7px 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 5px;
}

.layout-sidebar-right a:hover {
  background-color: white;
}

.layout-sidebar-right a.active {
  background-color: white;
  color: var(--primary-text-color);
  font-weight: 600;
}

/* end right sidebar bar */
/* login */

.login {
  position: relative;
  margin: 60px 40px;
  text-align: center;
}

.login-logo img {
  max-width: 80%;
}

.login:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC");
  width: 244px;
  height: 243px;
  content: " ";
  position: absolute;
  top: -54px;
  left: -46px;
}

.login:after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARAAAAEQCAMAAABP1NsnAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAABEKADAAQAAAABAAABEAAAAAAQWxS2AAAAwFBMVEUAAAD///+AgICAgP9VVaqqVf+qqv+AgL+AgP9mZsxmZv+ZZv+AgNWAgP9tbdttbf+Sbf+AYN+AgN+AgP9xceNmZv+AZuaAZv90dOh0dP9qav+AauqAav+AgP92dv9tbf+Abe2Abf93Zu53d+6AcO94afCAcfF5a+R5a/JzZuaAZvKAc/J5bed5bfOAaPN6b/R1auqAavR6ZvV6cPV2bOuAbPV7aPZ2be2AbfZ7au17avZ3Zu53b+57a+97a/d4aO9J6CoeAAAAQHRSTlMAAQICAwMDBAQFBQUGBgcHBwgICAkKCgoLCwwMDAwNDg4ODw8QERITExQUFBUVFhcYGBkZGhobHBwdHR4eHx8gJ5uMWwAAA/FJREFUeNrt2G1XEkEYxvHZNk2xHGzdbKFl0cTwgdSkCKzu7/+t4pw6sAjtjIueE/f8r3fMO35nZnbuy5gVGcvfzJe0rnTfGI+MggGJRUZnbpPIhJKt88nU53JnFULvyISY6KAv8vPj0vr2rYwiE2Z2B9J+uNYcyyQxwWZvaeGH3G4bMjsvI/kcwTC/V+7kLoahlITzQojP3ZFgsJCh7IJQzpX0QFj4uMiY18eDMZ9bZCF9OQahnK6cm/Y7js0sh/LF3Auv1PlQd3MxbdXYIQspV44EEEAAAWTNDAYYkKdJbNMsLzYueZbaZ2iM46RVbHBaiZ9Js+nHEdli42N9XuSen5hGp1CQTuOJQDRsD99N4gMSpYWapNH6IJo83CIeILZQFesEaber79NCWRoukOpNEnW0gXQqD81w6ACxhbrYde7VuFCYeA2QRCNIsgZISyNIqz6IyhPjOjNVIFYniK3dmKU6QdLaJUimEySrDZLrBMlrgxRKU7sxCw/EMe0CAggggADySJCqxixIkKpNEh6IozELD8RxjQACCCCAAPJIkKrGLEgQXqqAAEJjxrQLCCCAAEJjRmNGY8a0CwgggABCYwYIfQgggNCYMe0CAggggNCY0ZjRmDHtAgIIIIAAQmNGHwIIIDRmTLuAAAIIIDRmNGY0Zky7gAACCCCA0JjRhwACCI0Z0y4ggAACCI0ZjRmNGdMuIIAAAgggNGb0IYAAQmPGtAsIIIAAQmNGY0ZjxrQLCCCAAAIIjRl9CCCA0Jgx7QICCCCA0JjRmNGYMe0CAggggABCY0YfAgggNGZMu4AAAgggNGY0ZjRmTLuAAAIIIIDQmNGHAAIIjRnTLiCAAAIIjRmNGY0ZIEy7gAACCCA0ZvQhgABCY8a0CwgggABCY0ZjBgiNGdMuIIAAAgiN2f/Sh+Q6PfLaIJlOkKw2SKoTJK3dmFmdILb2tBvrBIlrg5iWRo+WqQ+SaARJ1gCJAzsxThCN16p1vNurGjNjoo42j07kAHFskoY2kEbl33U0ZgoPjXW+Rl0gkarnahqtDaJKxMPDDWIiNafGenh4gExvVhXfmk7Da6L1AVGxSby2h6MxK79Zk42ea1pJbJ48sU2zDezQ8iy1z6BBwoyjMQsvXp8YQAAhgADilRfyy+wf8WqZZUfGZihvgZiB3FybC+kCUU5XLkAo50C+gbBQdUzkAIVyejIAYfFTI1solHP2HgNCnHn5AYNy4jvpoVB6fVzL91cwzLJ9Lfd7S0jhehxO5H5/yePr1W6gHonI7fJ5ORSR/n6Q2yQanq763zuXU5LJZRKiyD/W9/pjkdPZz0/yJ8fqVyry+qQZDMjJKoDfy8bRVhHhQTwAAAAASUVORK5CYII=");
  width: 272px;
  height: 272px;
  content: " ";
  position: absolute;
  bottom: -45px;
  z-index: -1;
  right: -75px;
}

/* end login */
/* content */

.layout-content {
  margin-left: 260px;
  padding: 6.75rem 2rem 1rem;
  color: var(--body-fg-color);
  position: relative;
}

.vertical-menu.menu-collapsed .layout-content {
  margin-left: 80px;
}

.horizontal-menu .layout-content {
  margin-left: 0px;
  padding-top: 150px;
}

/* content end */
/* cards */

.p-card {
  /*border: none;*/
  border: 1px solid #eee;
  margin-bottom: 2rem;
  -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  /*box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);*/
  box-shadow: none !important;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .428rem;
  -webkit-transition: all .3s ease-in-out, background 0s, color 0s, border-color 0s;
  transition: all .3s ease-in-out, background 0s, color 0s, border-color 0s;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.p-card .p-card {
  background-color: #f9f9f9;
}

.p-card-min-h-300 {
  min-height: 300px;
}

.p-card-title {
  position: relative;
  font-size: 15px !important;
  /*font-family: inherit !important;*/
  font-weight: 500 !important;
  line-height: 1.2 !important;
  color: #5e5873 !important;
  margin-bottom: 0px !important;
}

.p-card-title .p-card-title-actions {
  position: absolute;
  right: 5px;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.p-card-title .p-card-title-actions a {
  padding: 5px 10px;
  cursor: pointer;
  color: var(--primary-text-color);
  border-radius: 5px;
}

.p-card-title .p-card-title-actions a:hover {
  background-color: #f9f9f9 !important;
}

.p-card-content {
  padding-bottom: 0px !important;
}

.p-card-content-fit .p-card-title {
  padding: 1rem !important;
}

.p-card-content-fit .p-card-body {
  padding: 0px !important;
}

.p-card-content-fit .p-card-content {
  padding: 0px !important;
  border-bottom-left-radius: .428rem;
  border-bottom-right-radius: .428rem;
  overflow: hidden;
}

/* end cards */
/* table */

table {}

table thead th {
  border-top: 1px solid #ebe9f1 !important;
  border-bottom: 2px solid #ebe9f1 !important;
  background-color: #f3f2f7 !important;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .5px;
  color: #6e6b7b !important;
  font-weight: 700 !important;
  padding: 0.5rem 1rem !important;
}

table tr {
  background-color: white;
  cursor: pointer;
}

table tr:hover {
  background-color: #fbfbfb !important;
}

table td {
  background-color: transparent;
  padding: 10px 10px;
  color: #6e6b7b;
  font-size: 13px;
}

table .grid-actions {
  text-align: right !important;
}

table .grid-actions>div {
  display: flex;
  justify-content: end;
}

table .grid-actions a {
  padding: 7px 5px;
  border-radius: 5px;
  color: #6e6b7b;
}

table .grid-actions a:hover {
  background-color: #f9f9f9;
}

@media(max-width: 768px) {
  table .grid-actions>div {
    justify-content: start;
  }

  table .grid-actions a {
    padding: 7px 10px;
  }
}

table .p-sortable-column[aria-sort="none"] {
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke='%23C6C4D0' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6M13 7L7 1 1 7'/%3E%3C/g%3E%3C/svg%3E") !important;
  background-position: right .36rem center !important;
  background-repeat: no-repeat !important;
  background-size: .65em 1em !important;
}

table .p-sortable-column[aria-sort="ascending"] {
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6' stroke='%23C6C4D0'/%3E%3Cpath d='M13 7L7 1 1 7' stroke='%235E5873'/%3E%3C/g%3E%3C/svg%3E") !important;
  background-position: right .36rem center !important;
  background-repeat: no-repeat !important;
  background-size: .65em 1em !important;
}

table .p-sortable-column[aria-sort="descending"] {
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6' stroke='%235E5873'/%3E%3Cpath d='M13 7L7 1 1 7' stroke='%23C6C4D0'/%3E%3C/g%3E%3C/svg%3E") !important;
  background-position: right .36rem center !important;
  background-repeat: no-repeat !important;
  background-size: .65em 1em !important;
}

.mobile {
  display: none !important;
}

.no-mobile {
  /*display: table-cell !important;*/
  display: unset !important;
}


/* end table */
/* fuentes */
.p-inputtext {
  font-family: Montserrat, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
  font-size: 13px !important;
  /*border: 1px solid rgb(96 96 96 / 40%) !important;*/
}

.p-field .p-multiselect .p-multiselect-label {
  font-size: 13px !important;
}

/* end fuentes */
/* form */
.p-field .p-inputtext.ng-invalid {
  //box-shadow: inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020;
  border: 1px solid #b00020;
}

.p-field .ng-invalid .p-dropdown {
  //box-shadow: inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020;
  border: 1px solid #b00020;
}

::placeholder {
  color: #d9d9d9 !important;
}


.p-field label {
  display: block;
  font-size: 13px;
}

.p-field input {
  display: block;
  width: 100%;
  padding: 5px 10px;
  color: #6e6b7b;
}

.p-field textarea {
  display: block;
  width: 100%;
  padding: 5px 10px;
  color: #6e6b7b;
}

.p-field .p-inputmask {
  display: block;
  width: 100% !important;
  padding: 5px 10px;
  color: #6e6b7b;
}

.p-field .p-password {
  display: block;
  width: 100%;
  color: #6e6b7b;
}

.p-field .p-dropdown {
  width: 100% !important;
  color: #6e6b7b;
}

.p-field .p-dropdown>span {
  padding: 5px 10px;
}

.p-field .p-splitbutton {
  height: 100%;
}

.p-field .p-calendar-w-btn .p-datepicker-trigger.p-button .p-icon {
  color: white;
}

/* end form */
/* botones */

.p-button {
  padding: 7px 10px;
  font-size: 13px;
  background-color: var(--color-primary);
}

.p-button-secondary {
  background-color: var(--color-secondary) !important;
}

.p-button-danger,
.p-button-danger:enabled {
  background-color: var(--color-danger) !important;
}

/* end botones */

/* breadcrumb */

.page-title {
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

ul.breadcrumb {
  list-style-type: none;
  padding: 0;
  margin-top: 0px;
  margin-bottom: 20px;
}

ul.breadcrumb li {
  display: inline-block;
  padding-right: 20px;
  padding: 0;
  padding-right: 12px;
  font-size: 12px;
  font-weight: normal;
  color: #657380;
  text-transform: uppercase;
}

ul.breadcrumb li[hidden] {
  display: none;
}

ul.breadcrumb li i {
  font-size: 10px;
}

/* end breadcrumb */



a {
  color: #4EA294;
}

table th a {
  color: #6e6b7b !important;
}

.p-card {
  margin-bottom: 0px !important;
}

.p-card .p-card-title {
  padding: 1rem !important;
}

.p-card .p-card-content {
  padding: 0px !important;
}

.p-card .p-card-title small {
  display: block;
  color: #cccccc;
}

.p-card .p-card-title .p-card-title-actions {
  /*top: 5px !important;
  align-items: start;*/
  align-items: center;
}

.p-card .p-card-body {
  padding-top: 0px !important;
}

.p-card-scrollable .p-card-body {
  overflow-y: auto !important;
}

.p-dialog-footer-sticky .p-dialog-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-top: 1px solid #9e9e9e2e;
}

.p-dialog-footer-sticky .p-dialog-custom-header {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 100%;
  padding: 0.8rem !important;
  margin: 0px !important;
  background: #ffffff;
  z-index: 10000;
  border-bottom: 1px solid #9e9e9e2e;
}
.p-dialog-footer-sticky .p-dialog-custom-header input {
  background: #ffffff;
}
.p-dialog-footer-sticky .p-dialog-content {
    padding: 0px;
    padding-bottom: 100px;
}
.p-dialog-footer-sticky .p-dialog-content-if-header {
  padding-top: 66px !important;
}

.p-dialog .p-card {
  background: #f9f9f9;
  border: 2px solid #f9f9f9;
  box-shadow: none;
}

.p-dialog .p-tabview .p-tabview-panels {
  background: #f9f9f9;
}

.p-tabview .p-tabview-title {
  display: flex;
}

.p-tabview .p-tabview-title .badge {
  font-size: 10px;
  padding: 3px 10px;
}

/** pasar al thema */
[hidden] {
  display: none !important;
}

.p-inputgroup p-dropdown {
  flex-grow: 1;
}

.p-inputgroup-with-addons .p-dropdown {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-inputgroup-with-addons.p-calendar-w-btn {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-inputgroup .p-button {
  min-width: auto;
}

.p-field p-calendar {
  width: 100%;
}

.p-field .p-calendar {
  width: 100%;
}

.p-field {
  /*padding-bottom: 0px;*/
}

.p-field>label:first-of-type {
  display: block !important;
  font-size: 10px !important;
  font-weight: 600;
  margin-bottom: 3px !important;
}

.p-field>label:not(:first-of-type),
.p-field .p-inputgroup label {
  display: block !important;
  width: 100%;
  font-size: 13px !important;
  /*padding: 5px 0px !important;*/
  margin-bottom: 0px !important;
  background: #f9f9f9;
  padding: 6px !important;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 26px;
}

.p-dialog .p-card .p-field .p-inputgroup label {
  background: white;
}

/*.p-inputgroup label:before {
  content: "\00a0 ";
}*/

.p-field .p-dropdown>span {
  width: 50px;
}

.p-toast .p-toast-message {
  font-size: 12px !important;
}

.p-toast .p-toast-message .p-toast-summary {
  margin-bottom: 3px;
}

.p-toast .p-toast-message .p-toast-detail {
  margin: 0px !important;
}

.p-toast .p-toast-message-icon {
  font-size: 12px !important;
  margin-top: 5px;
}

.p-button {
  font-size: 13px !important;
  padding: 5px 10px !important;
  font-weight: normal !important;
  background-color: #4EA294/* !important*/;
}

.p-button-primary {
  border: 1px #4EA294 solid !important;
}

.p-button.p-button-danger.p-button-text {
  background-color: transparent !important;
}

.p-button-text {
  background-color: transparent !important;
  border: 1px transparent solid !important;
}

.p-button-link {
  background-color: transparent !important;
}

.p-button-link .p-button-label {
  text-decoration: none !important;
}

.p-button-link.p-button-secondary:hover {
  background-color: #f9f9f9 !important;
}

.p-button-link.p-button-secondary {
  color: #6e6b7b !important;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary>.p-button.p-button-text,
.p-splitbutton.p-button-secondary>.p-button.p-button-text {
  color: #6e6b7b !important;
}

.p-button-link.p-button-danger {
  color: rgba(211, 47, 47, 1) !important;
}

.p-button-link.p-button-danger:hover {
  background-color: rgba(211, 47, 47, 0.16) !important;
}

.p-button.p-button-icon-only.p-button-icon-only-sm {
  width: 20px;
}

/* carrousel */
.custom-carrousel {
  background-color: white !important;
}

/* table */
.p-paginator {
  font-size: 14px !important;
  padding: 0px !important;
}

.p-paginator .p-paginator-element {
  height: 2rem !important;
  min-width: 2rem !important;
  border-radius: 5px !important;
}

.p-paginator .p-dropdown {
  height: 2rem !important;
}

.p-paginator .p-dropdown .p-inputtext {
  padding: 5px !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #4EA294 !important;
  color: white !important;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #CEEDE6 !important;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #CEEDE6 !important;
}

/*TIMELINE*/
.timeline {
  padding: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  list-style: none;
}

.timeline .timeline-item {
  padding-bottom: 1.8rem;
  position: relative;
  padding-left: 2.5rem;
  border-left: 1px solid #ebe9f1;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator {
  left: -.412rem;
  top: .07rem;
  height: 12px;
  width: 12px;
  border: 0;
  background-color: var(--primary-text-color);
  position: absolute;
  border-radius: 50%;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator:before {
  content: "";
  background: rgba(115, 103, 240, .12);
  height: 20px;
  width: 20px;
  display: block;
  position: absolute;
  top: -.285rem;
  left: -.285rem;
  border-radius: 50%;
}

/*Badges*/

.badge {
  display: inline-block;
  max-width: 100%;
  padding: .3rem .5rem;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  /*white-space: nowrap;*/
  vertical-align: baseline;
  border-radius: .358rem;
  color: #fff;
}

.badge-block {
  display: block;
}

.badge-primary {
  color: #fff;
  background-color: #0073ff;
}

.badge-secondary {
  color: #fff;
  background-color: #82868b;
}

.badge-warning {
  color: #fff;
  background-color: #ff9f43;
}

.badge-success {
  color: #fff;
  background-color: #28c76f;
}

.badge-danger {
  color: #fff;
  background-color: #e42728;
}


.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.text-secondary {
  color: #82868b;
}

.text-warning {
  color: #ff9f43;
}

.text-success {
  color: #28c76f
}



.hide-oposite .p-timeline-event-opposite {
  display: none;
}

/*
tablas
*/
.p-datatable {
  width: 100%;
}

.p-datatable-scrollable-header {
  background-color: rgb(243, 242, 247) !important;
}

.p-datatable .p-datatable-thead>tr>th:not(.p-sortable-column):first-of-type,
.p-datatable .p-datatable-thead>tr>th.p-sortable-column:first-of-type {
  padding-left: 1rem !important;
}

.p-datatable-scrollable-body {
  overflow-y: auto !important;
}

.p-datatable .p-datatable-tbody {
  overflow-wrap: break-word;
}

.p-datatable .p-datatable-tbody>tr>td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: normal;
  /*width: 100%;*/
  text-wrap: nowrap !important;
}

.p-datatable .p-datatable-tbody>tr>td:first-of-type {
  padding-left: 1rem !important;
}

.p-datatable .p-datatable-tbody>tr>td:last-of-type {
  padding-right: 1rem !important;
}

.p-datatable .p-datatable-footer {
  background: #f3f2f7 !important;
  color: #6e6b7b !important;
  padding: 0.5rem 1rem !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.p-datatable .p-datatable-tfoot>tr>td {
  padding: 10px 5px !important;
}

.p-datatable .p-datatable-tfoot>tr>td {
  color: #6e6b7b !important;
}

/*
buscador superior
*/
.searchbox {
  width: 35% !important;
  background-color: white !important;
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #c7c7c7;
  height: 30px;
  align-self: center;
}

.searchbox .p-inputtext {
  background-color: transparent;
  padding: 5px 10px;
  margin-left: 10px;
  border: 0;
  width: 100%;
}

.searchbox .p-inputtext:enabled:focus {
  box-shadow: none !important;
  border: none;
}

/*
view configurator
*/
.view {
  position: relative;
}

.view-top-actions {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: -34px;
}

.p-dialog-content .view-top-actions {
  position: fixed;
  z-index: 1;
  right: 65px;
  top: 29px;
  color: gray;
}

.view-top-actions a {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.view-top-actions a:hover {
  background-color: #e9e9e9;
}

/*
menu lateral
*/
.p-panelmenu .p-panelmenu-panel {
  border: none !important;
  box-shadow: none !important;
  font-size: 13px !important;
}

.p-panelmenu .p-panelmenu-panel a {
  padding: 10px 1.5rem !important;
}

.p-panelmenu .p-panelmenu-header>a {
  background: #606060 !important;
  color: white !important;
}

.p-panelmenu .p-panelmenu-header-content>a.p-menuitem-link-active {
  background-color: #4EA294 !important;
  background-image: url(assets/img/flechaMenu.png) !important;
  background-position: right !important;
  background-repeat: no-repeat !important;
  background-size: 13px 15px !important;
}

.p-panelmenu .p-panelmenu-content {
  background: #606060 !important;
  color: white !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  color: white !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: white !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon.color-white {
  color: white !important;
}

.p-panelmenu-header-link {
  font-size: 13px !important;
}

.p-panelmenu-icon {
  font-size: 13px !important;
}

.p-submenu-list {
  padding-left: 20px !important;
}

.p-submenu-list .p-menuitem-link {
  font-size: 11px !important;
}

.p-submenu-list .p-menuitem-icon {
  font-size: 11px !important;
}

.titleMenu {
  color: #bababa;
  padding: 10px 10px;
  display: block;
  font-weight: 600;
}

.p-placeholder {
  color: #d9d9d9 !important;
}

/*
pipes
*/
ul.step-line {
  list-style: none;
  /*border: 1px solid #f9f9f9;*/
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  font-size: 11px;
  text-align: center;
}

ul.step-line li {
  display: inline-block;
  padding: 5px 15px;
  padding-left: 25px;
  background-color: #e9e9e9;
  position: relative;
  cursor: pointer;
}

ul.step-line li .separator {
  background-color: #e9e9e9;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  height: 22px;
  width: 22px;
  position: absolute;
  top: 3px;
  right: -12px;
  z-index: 9;
}

ul.step-line li:last-of-type .separator {
  display: none;
}

ul.step-line li:first-of-type {
  padding-left: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

ul.step-line li:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

ul.step-line li.bg-success,
ul.step-line li.bg-success .separator {
  background-color: #28c76f;
  color: white;
}

ul.step-line li.border-success,
ul.step-line li.border-success .separator {
  color: #28c76f;
}

ul.step-line li.bg-warning,
ul.step-line li.bg-warning .separator {
  background-color: #ff9f43;
  color: white;
}

ul.step-line li.border-warning,
ul.step-line li.border-warning .separator {
  color: #ff9f43;
}

ul.step-line li.bg-danger,
ul.step-line li.bg-danger .separator {
  background-color: #ea5455;
  color: white;
}

ul.step-line li.bg-info,
ul.step-line li.bg-info .separator {
  background-color: #00cfe8;
  color: white;
}

ul.step-line li.bg-secondary,
ul.step-line li.bg-secondary .separator {
  background-color: #82868b;
  color: white;
}

ul.step-line li.border-secondary,
ul.step-line li.border-secondary .separator {
  color: #82868b;
}

/*
tareas 
*/
.task-row-delayed {
  border-left: 3px solid #e42728 !important;
  /*background-color: #ffe0e0 !important;*/
}

.task-row-delayed td {
  color: #e42728;
}

.contact-row-disabled {
  border-left: 3px solid #e42728 !important;
}

.contact-row-disabled td,
.contact-row-disabled .card-title,
.contact-row-disabled .card-subtitle {
  color: #e42728 !important;
}

.task-timeline-card .p-card .p-card-body {
  max-height: 450px !important;
}

.layout-sidebar-right {
  margin-left: 0px;
  background-color: white;
  border-radius: 5px;
  margin-top: 38px;
  padding-top: 0px;
}

.layout-sidebar-right a {
  font-size: 14px;
}

.layout-sidebar-right a.active {
  background-color: #e9e9e9;
  border-radius: 5px;
  color: #4EA294 !important;
}

/*
menú
*/
@media(max-width: 768px) {
  .layout-content {
    margin-left: 0px;
  }

  .p-dialog {
    max-height: 100% !important;
    /*height: 100% !important;*/
    width: 100% !important;
  }
}

@media(max-width: 40em) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody>tr>td {
    padding: 3px 10px !important;
  }
}

.p-grid {
  /*align-items: flex-start;*/
}

p-autocomplete {
  width: 100%;
}

.p-autocomplete {
  width: 100% !important;
}

.p-autocomplete .p-inputtext {
  border-radius: 4px !important;
}

.multiselect-custom.multiselect-custom-header .p-multiselect .p-multiselect-label {
  padding: 10px;
}

.multiselect-custom .p-multiselect {
  width: 100%;
}

.multiselect-custom .p-multiselect .p-multiselect-label {
  padding: 5px;
}

.p-accordion .p-accordion-tab.p-accordion-tab-active {
  margin-bottom: 0px !important;
}

/* colro del tab... falta la linea, no se quien se la pone*/
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #4EA294 !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #4EA294 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
  background-color: rgb(77 75 76 / 5%) !important;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: rgb(77 75 76 / 5%) !important;
}

/* color de los checkboxes*/
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #4EA294 !important;
  background: #4EA294 !important;
  color: #ffffff !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #4EA294 !important;
  background: #4EA294 !important;
  color: #ffffff !important;
}

/* scrollbars */
body ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #4EA294;
}

body ::-webkit-scrollbar-track {
  background: #EAEAEA;
}

body ::-webkit-scrollbar-thumb {
  background: #4EA294;
}

body ::-webkit-scrollbar-thumb:hover {
  background: #4EA294;
}

.p-datatable ::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: #4EA294;
}

.p-datatable-flex-scrollable {
  display: flex;
}

/* end scrollbars */

.p-field .p-cascadeselect .p-cascadeselect-label {
  padding: 5px 15px;
}

.p-field .p-cascadeselect {
  width: 100%;
  color: #6e6b7b;
}

.p-cascadeselect-item-active>.p-cascadeselect-sublist .p-cascadeselect-panel {
  max-height: 325px;
  overflow: scroll;
}

.fieldsetReports .p-field {
  margin-bottom: 0px !important;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button span {
  color: rgb(255 255 255 / 70%) !important;
}

.p-card-title .p-card-title-actions a {
  color: #4EA294 !important;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined {
  color: white !important;
}

.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help>.p-button:enabled:hover,
.p-splitbutton.p-button-help>.p-button:enabled:hover {
  background: rgba(156, 39, 176, 0.92) !important;
}

#importStep1 .p-fileupload .p-fileupload-row>div {
  padding: 0px !important;
}

#importStep1 .p-fileupload .p-fileupload-buttonbar {
  padding: 10px !important;
}

#importStep1 .p-fileupload .p-fileupload-content {
  padding: 10px !important;
}

#import .p-message.p-message-info {
  white-space: pre-line;
}
.p-panel {
  box-shadow: none;
  border: 1px solid #e9e9e9;
}
.p-panel-no-border {
  border: 0px !important;
}
.p-panel-header-disable .p-panel-header {
  display: none;
}
.p-panel .p-panel-header {
  /*color: #90cec4 !important;*/
  border-bottom: 1px solid #e9e9e9;
}
.p-panel-no-border  .p-panel-header {
  border-top: 1px solid #e9e9e9;
}
.p-panel .p-panel-title {
  color: #222222;
  font-size: 15px;
}
.p-panel .p-panel-content {
  padding-top: 1rem;
}

.p-dialog .p-panel-debug {
  background-color: #f9f9f9;
  border: 2px solid #f9f9f9;
  box-shadow: none;
}

.p-dialog .p-panel-debug .p-panel-header {
  background-color: transparent;
}

.p-panel-debug.p-panel .p-panel-title {
  color: #5e5873 !important;
  font-size: 15px !important;
}

.p-panel-debug .p-panel-content {
  background-color: transparent !important;
  font-family: monospace;
  font-size: 10px;
}

.p-badge {
  //background-color: #e42728 !important;
  background-color: #4EA294 !important;
}

.p-badge.p-badge-secondary {
  background-color: #ff4081 !important;
  color: #ffffff;
}

.p-badge.p-badge-success {
  background-color: #689F38 !important;
  color: #ffffff;
}

.p-badge.p-badge-info {
  background-color: #2196F3 !important;
  color: #ffffff;
}

.p-badge.p-badge-warning {
  background-color: #FBC02D !important;
  color: #212529;
}

.p-badge.p-badge-danger {
  background-color: #D32F2F !important;
  color: #ffffff;
}

.p-badge.p-badge-xs {
  font-size: 12px;
  min-width: 12px;
  height: 12px;
  line-height: 12px;
}

//Selector de imagen
.app-image-field {
  display: table;
}

.app-image-field>label {
  display: table-row;
  font-size: 10px;
  color: #676a6c;
}

.app-image-field .separator {
  display: table-row;
  height: 5px;
}

.app-image-field>.image-container>.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  z-index: 999;
}

.app-image-field>.image-container>.view-image {
  position: absolute;
  top: 5px;
  right: 40px;
  z-index: 999;
}

.app-image-field>.image-container {
  display: table-cell;
  border: 1px solid #cccccc;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  text-align: center;
}

.app-image-field>div {
  display: block;
  vertical-align: middle;
  text-align: center;
  height: 100%;
  position: relative;
}

.app-image-field>.image-container>i {
  font-size: 16px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.app-image-field input {
  display: none;
}

.app-image-field img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.fc.fc-theme-standard .fc-toolbar .fc-prev-button,
.fc.fc-theme-standard .fc-toolbar .fc-next-button,
.fc.fc-theme-standard .fc-toolbar .fc-today-button,
.fc.fc-theme-standard .fc-toolbar .fc-prev-button:enabled:hover,
.fc.fc-theme-standard .fc-toolbar .fc-next-button:enabled:hover,
.fc.fc-theme-standard .fc-toolbar .fc-today-button:enabled:hover {
  background: #4EA294 !important;
}

.fc .fc-scrollgrid-section-header>th {
  padding: 0px !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
}

.user-calendar .fc .fc-left,
.user-calendar .fc .fc-right {
  margin-left: 5px;
  margin-right: 5px;
}

.user-calendar .fc .fc-left button,
.user-calendar .fc .fc-right button {
  height: 25px !important;
  width: 25px !important;
}

.user-calendar .fc-prev-button {
  margin-left: 8px;
}

.user-calendar .fc-next-button {
  margin-right: 8px;
}

#calendarMeeting .fc-toolbar .fc-left .fc-button,
#calendarMeeting .fc-toolbar .fc-right .fc-button {
  padding-left: 4px;
}

#calendarMeeting .fc-toolbar h2 {
  font-size: 14px !important;
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #4EA294, inset 0 0 0 1px #4EA294, inset 0 0 0 1px #4EA294, inset 0 0 0 1px #4EA294 !important;
  border-color: #4EA294 !important;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #4EA294, inset 0 0 0 1px #4EA294, inset 0 0 0 1px #4EA294, inset 0 0 0 1px #4EA294 !important;
  border-color: #4EA294 !important;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  background: rgb(63 181 110 / 12%) !important;
  color: #4EA294 !important;
  padding: 2px 6px !important;
  font-size: 15px !important;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-label {
  font-family: Montserrat, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0px 10px !important;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 5px !important;
}

#reportMailMarketing .p-fileupload .p-fileupload-content {
  padding: 0px !important;
}

#reportMailMarketing .p-fileupload .p-fileupload-content ul {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-size: 12px !important;
  list-style-type: none;
  padding-left: 16px;
}

#mailDetail .p-fileupload .p-fileupload-content {
  padding: 0px !important;
}

#mailDetail .p-fileupload .p-fileupload-content ul {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-size: 12px !important;
  list-style-type: none;
  padding-left: 16px;
}

/* customizer */
.customizer-item-placeholder-start {
  position: relative;
  /*padding-left: 18px !important;*/
}

.customizer-item-placeholder-start::before {
  content: "";
  position: absolute;
  left: -7px;
  top: 5px;
  height: 100%;
  width: 14px;
  background-image: url(assets/img/reorder-placeholder.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.customizer-item-placeholder-end {
  position: relative;
  /*padding-left: 18px !important;*/
}

.customizer-item-placeholder-end::after {
  /*important para que sobreescriba el resizer*/
  content: "" !important;
  position: absolute;
  right: -7px !important;
  top: 5px !important;
  height: 100% !important;
  width: 14px !important;
  background-image: url(assets/img/reorder-placeholder.png) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.customizer-item-resizable {
  position: relative;
}

.customizer-item-resizable::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 3px;
  height: 7px;
  width: 7px;
  background-image: url(assets/img/resize-corner.png);
  background-repeat: no-repeat;
  background-size: cover;
  cursor: col-resize;
}

.p-inputgroup .entity-field-configure {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 10px;
  color: #444444;
}

th .entity-field-configure {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 10px;
  color: #444444;
  background-color: #f3f2f7;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 8px;
}

th .entity-field-configure:hover {
  background-color: #e9e9e9;
}

/** graficos */
p-chart canvas {
  max-width: 100% !important;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

/** configurator */
.configuration-mode {
  .layout-col {
    .placeholder {
      font-size: 16px;
      text-align: center;
      background: #e9e9e9;
      border: 3px dashed #cccccc;
      padding: 10%;
      margin: 10px;
      border-radius: 8px;
      width: 100%;
    }
  }
}

/** p-table **/
.p-datatable-table {
  border-collapse: collapse;
}

/** end p-table **/

/** multiselect */
p-multiselect {
  width: 100%;
}

.p-field .p-multiselect {
  width: 100% !important;
}

.p-field .p-multiselect .p-multiselect-label {
  padding: 5px 10px;
  font-family: Montserrat, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
}

.p-multiselect .p-multiselect-label-container {
  display: flex;
}

.cardHome {
  text-align: center;
  white-space: normal;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.p-datatable .p-datatable-thead>tr>th:not(.p-sortable-column) {
  padding: 10px 5px !important;
}

.p-datatable .p-datatable-thead>tr>th.p-sortable-column {
  padding: 10px 20px 10px 5px !important
}

.p-datatable .p-datatable-thead>tr>th {
  white-space: normal !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 10px 5px !important;
}

.p-datatable-scrollable-header-box,
.p-datatable-scrollable-footer-box {
  padding-right: 0px !important;
}

th:hover {
  background-color: #e9e9e9 !important;
}

.p-datatable-resizable>.p-datatable-wrapper {
  overflow-x: unset !important;
}

#tableImportErrors {
  height: 20px;
  overflow: scroll;
  border-color: #fefefe;
}

.p-progressbar {
  height: 25px !important;
  z-index: 100000;
  border-radius: 5px !important;
  background: #CCCCCC !important;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #4ea294 !important;
}

.p-progressbar .p-progressbar-label {
  color: white !important;
  line-height: 23px !important;
}

.bg-transparent {
  background: transparent !important;
}

.font-semibold {
  font-weight: 700
}

.color-green-axial {
  color: #4ea294 !important;
}

.color-soft-grey-axial {
  color: #cfcfcf !important;
}

.color-grey-axial {
  color: #6e6b7b !important;
}

.button-only-icon {
  min-width: auto !important;
}

.customButton {
  padding: 0px 3px !important;
}

.headerHome {
  display: flex;
  align-items: center;
  justify-content: normal;
  flex-wrap: wrap;
}

.headerHome .blockHeaderHome {
  margin-right: 10px;
  border: 1px solid #4ea294;
  background-color: white;
  margin-bottom: 10px;
  /*height: 40px;*/
}

.headerHome .blockHeaderHome a {

  color: #606060;
  display: block;
  cursor: pointer;
}

.headerHome .blockHeaderHome:hover {
  background-color: #c8e5de;
}

.headerHome .blockHeaderHome a .label {
  float: left;
  padding-top: 10px;
  padding-right: 10px;
}

.headerHome .blockHeaderHome a .icon {
  float: left;
  padding: 10px;
}

.headerHome .blockHeaderHome i {
  color: #4ea294;
}

.mailAttachments {
  font-size: 13px;
  margin-bottom: 25px;
}

.mailAttachments .checkbox {
  margin-top: 15px;
}

.mailAttachments img {
  max-width: 100%;
  max-height: 30px;
}

.buttonSelectFromAxial {
  position: absolute;
  top: 41px;
  left: 175px;
}

.vl {
  border-left: 1px solid #cfcfcf;
  height: 40px;
  margin-left: 15px;
  padding-right: 15px;
}

.slideMenuTopbar .p-slidemenu-wrapper {
  font-size: 14px;
  width: 100% !important;
}

.slideMenuTopbar .p-slidemenu-wrapper ul {
  width: 100% !important;
}

.slideMenuTopbar.p-slidemenu .p-menuitem-link {
  padding: 0.5rem 0.8rem !important;
}

.showConfigOptions.p-card {
  background-color: transparent;
  border-bottom: 1px solid #cfcfcf;
  border-radius: 0px;
  box-shadow: none;
}

.showConfigOptions.p-card .p-card-title {
  color: #4ea294 !important;
  font-size: 16px !important;
}

#reportReport .p-multiselect .p-multiselect-label {
  padding: 5px !important;
}

.p-calendar .p-datepicker-touch-ui,
.p-datepicker-touch-ui {
  min-width: unset !important
}

.p-dropdown.p-disabled,
.p-dropdown.p-disabled-custom {
  opacity: 1;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  background-color: #f9f9f9;
  pointer-events: none;
}

.p-dialog .p-card .p-field .p-dropdown.p-disabled,
.p-dialog .p-card .p-field .p-dropdown.p-disabled-custom {
  background: white;
}

.p-field .p-dropdown.p-disabled>span {
  padding: 6px 10px;
}

.p-field .p-dropdown.p-disabled .p-placeholder,
.p-field .p-dropdown.p-disabled-custom .p-placeholder {
  visibility: hidden;
}

.p-dropdown.p-disabled .p-dropdown-trigger,
.p-dropdown.p-disabled-custom .p-dropdown-trigger {
  display: none;
}

.p-dropdown.p-disabled .p-dropdown-clear-icon,
.p-dropdown.p-disabled-custom .p-dropdown-clear-icon {
  display: none;
}

.checkbox-no-bg {
  border-radius: 0px !important;
}

.checkbox-no-bg .p-checkbox-box {
  background-color: transparent !important;
}

.checkbox-no-bg.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.checkbox-no-bg.p-checkbox .p-checkbox-box.p-highlight {
  background: none !important;
  border-color: #757575 !important;
}

.checkbox-no-bg.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
  top: 8px;
}

.checkbox-no-bg.checkbox-color-white .p-checkbox-icon.pi-check:before {
  border-color: white !important;
}

.checkbox-no-bg.checkbox-color-black .p-checkbox-icon.pi-check:before {
  border-color: #757575 !important;
}

.p-checkbox:not(.p-checkbox-disabled).p-checkbox-focused {
  box-shadow: none !important;
}


.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header>a {
  border-radius: 0px;
}

.hideButton .p-splitbutton-defaultbutton {
  display: none;
}


a.filterTasksByWeek.active,
a.filterTasksByDay.active,
a.filterTasksByMonth.active,
.p-card-title .p-card-title-actions a.filterTasksByWeek:hover,
.p-card-title .p-card-title-actions a.filterTasksByDay:hover,
.p-card-title .p-card-title-actions a.filterTasksByMonth:hover {
  background-color: #4EA294 !important;
  color: white !important;
}

a.filterMeetingsByWeek.active,
a.filterMeetingsByDay.active,
a.filterMeetingsByMonth.active,
.p-card-title .p-card-title-actions a.filterMeetingsByWeek:hover,
.p-card-title .p-card-title-actions a.filterMeetingsByDay:hover,
.p-card-title .p-card-title-actions a.filterMeetingsByMonth:hover {
  background-color: #4EA294 !important;
  color: white !important;
}


.layout-topbar .ablockHeaderHome .p-overlay-badge .p-badge {
  padding: 0px;
  font-size: 10px;
  min-width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  background-color: #990000 !important;
}

.p-inputgroup button:nth-last-child(1 of :not([hidden])),
.p-inputgroup p-splitbutton:nth-last-child(1 of :not([hidden])) {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

/* notifications */
.notification-status-pending {
  background-color: #ff9f43 !important;
}

.notification-status-error {
  background-color: #e42728 !important;
}

.notification-status-sent {
  background-color: #28c76f !important;
}

.notification-status-received {
  background-color: #17a2b8 !important;
}


/* end notifications */

/* erp connection */
.erp-status-connected {
  background-color: #28c76f !important;
}
.erp-status-disconnected {
  background-color: #ff9f43 !important;
}
label > .badge {
  margin: -5px;
  box-sizing: content-box;
  border-radius: 5px;
  height: 100%;
  display: flex;
  align-items: center;
}
/* end erp connection */


/* fullcalendar */
.fc .fc-axis {
  padding: 0 4px !important;
}

.fc .fc-timeGridWeek-view .fc-bg tr>td {
  border: 1px solid #e0e0e0;
}

.fc .fc-week table tr,
.fc .fc-slats table tr {
  background: transparent !important;
}

.fc .fc-view-container td.fc-widget-content {
  background: transparent !important;
}

.fc .fc-timeGridWeek-view .fc-scroller,
.fc .fc-timeGridDay-view .fc-scroller {
  /*height: 500px !important;*/
}

.fc .fc-event {
  background: rgba(63, 81, 181, .12);
  border: 1px solid rgba(63, 81, 181, .12);
  color: #3f51b5;
}

.fc .fc-event .fc-daygrid-event-dot {
  display: none;
}

.fc .fc-event .fc-event-time {
  font-weight: bold;
  overflow: unset;
}

.fc .fc-event .fc-event-title {
  font-weight: normal;
}

/* end fullcalendar */
@media(max-width: 768px) {
  .layout-content {
    margin: 0px !important;
    padding: 10px;
    padding-top: 4.75rem;
  }

  .vertical-menu.menu-collapsed .layout-topbar-backdrop {
    padding-left: 0px !important;
  }

  .no-mobile {
    display: none !important; //de momento lo ocultamos
  }

  .mobile {
    display: unset !important;
  }

  .mobile tr td:first-child {
    width: 100% !important;
  }

  #reports .p-card .p-card-title .p-card-title-actions {
    display: flex;
    position: relative;
    gap: 0.25rem !important;
    flex-flow: column;

  }

  #reports .buttonsReport .buttons {
    flex-direction: column;
    gap: 0.5rem !important;
    flex-flow: column-reverse;
  }

  /*Eliminamos la linea verde porque no la pinta bien en los moviles*/
  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none !important;
  }

  .p-card-title-actions a {
    padding: 5px 8px !important;
    font-size: 0.75em !important;
  }

  .p-card-title-actions a span {
    /* display: none;*/
    font-size: 0.75em !important;
  }

  .user-dropdown {
    img {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .breadcrumb {
    display: none !important; //de momento lo ocultamos
    padding: 0px 10px !important;
    margin-bottom: 0px !important;
  }

  .view .p-grid {
    display: block;
    margin: 0px !important;
  }

  .p-field label {
    white-space: normal !important;
  }

  .p-autocomplete .p-autocomplete-panel {
    left: -26px !important;
    width: 300px !important;
    max-height: 330px !important;
    display: flex;
  }
}

.tox-tinymce-aux {
  z-index: 99999 !important;
}

.integration-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.integration-list .integration {
  height: 100%;
}

.alertsTopbarBadge {
  padding: 0px !important;
  font-size: 10px !important;
  min-width: 1rem !important;
  height: 1rem !important;
  line-height: 1rem !important;
  background-color: #990000 !important;
  position: absolute;
  left: 25px;
  top: 0px;
}

app-view-component-datetime {
  width: 100%;
}

.custom-datetime-overlaypanel {
  /*margin-top: -10px !important;*/
}

.p-overlaypanel:before {}

.p-overlaypanel {
  -webkit-box-shadow: 0px 0px 5px 4px rgba(204, 204, 204, 1) !important;
  -moz-box-shadow: 0px 0px 5px 4px rgba(204, 204, 204, 1) !important;
  box-shadow: 0px 0px 5px 4px rgba(204, 204, 204, 1) !important;
}

.p-overlaypanel-flipped {
  margin-top: -10px !important;
}

.custom-datetime-overlaypanel-backdrop {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0,0,0,0.01);*/
}

.custom-datetime-overlaypanel .p-overlaypanel-content {
  padding: 0px !important;
}

.custom-datetime-input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.custom-datetime-input-upfront.p-inputgroup {
  z-index: 10000;
  position: relative;
}

.custom-datetime-selector .p-datepicker {
  border: 0px !important;
  padding: 0px;
}
.p-datepicker table td {
  padding: 0rem !important;
}


.p-datepicker .p-datepicker-header {
  padding: 0.5rem 0.5rem 0 0.5rem !important;
}
.p-datepicker table td > span {
  width: 2rem !important;
  height: 2rem !important;
  margin: 0px 5px !important;
}
.p-datepicker table thead th {
  padding: 5px !important;
}
.p-datepicker .p-timepicker{
    padding: 0px !important;
}
.p-datepicker .p-timepicker button {
  width: 1.8rem !important ;
  height: 1.8rem !important ;
}
.p-datepicker .p-timepicker span {
  font-size: 14px !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem !important;
  height: 2rem !important;
}

  .custom-time-selector {
    border-top: 1px solid #e9e9e9;
  }

  .bee-plugin-container {
    height: 800px !important;
  }


  .p-splitbutton-menubutton {
    width: auto !important;
    border-left: 1px solid #498077;
    padding-left: 7px !important;
  }

  .p-splitbutton-defaultbutton {
    background-color: #4EA294 !important;
  }

  .p-button, .p-button:hover, .p-button:enabled:hover {
    background-color: #4EA294;
    color: white;
  }

  .p-button:hover {
    opacity: 0.9;
  }

  .p-button-badge-exclamation {
    position: relative;
    overflow-x: visible;
    overflow-y: visible;
  }

  .p-button-badge-exclamation:before {
    content: "!";
    font-size: 9px;
    padding-top: 2px;
    color: white;
    background-color: var(--color-danger);
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: -5px;
    width: 16px;
    height: 16px;
    z-index: 1;
  }

  .p-sidebar .p-sidebar-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
  }

  .p-sidebar-content-no-padding .p-sidebar-content {
    padding: 0px !important;
  }

  .p-sidebar-content-no-footer .p-sidebar-footer {
    display: none;
  }

  .border-top {
    border-top: 1px solid #e9e9e9;
  }

  .border-bottom {
    border-bottom: 1px solid #e9e9e9;
  }

  .flagsDropdown .p-dropdown-label {
    padding: 2px 5px 4px !important;
  }

  .flagsDropdown .p-dropdown-trigger {
    width: 24px;
    padding-top: 2px;
  }
